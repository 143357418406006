import React, { useEffect, useMemo, useState } from 'react';
import styles from './PromoCodesPage.module.scss';
import Table from '../../components/Table';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { PromoCode } from '../../models/common';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import {
  getPromoCodesThunk,
  setLinkStatusThunk,
} from '../../store/promoCodesSlice/promoCodesThunk';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import Loader from '../../components/Loader';
import RadioButton from '../../components/RadioButton';

const PromoCodesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const promoCodes = useSelector((state: RootState) => state.promoCodes);
  const [editItem, setEditItem] = useState<PromoCode | null>(null);
  const [linkStatus, setLinkStatus] = useState('0');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useLoadingStatus('pending');

  useEffect(() => {
    async function init() {
      try {
        await dispatch(getPromoCodesThunk());
        setLoading('fulfilled');
      } catch (e) {
        setLoading('rejected');
      }
    }

    init();
  }, []);

  const onEdit = (item: PromoCode) => {
    setLinkStatus(item.link_added ? '1' : '0');
    setEditItem(item);
  };

  const onSave = async () => {
    setLoading('pending');
    try {
      if (editItem!.link_added !== (linkStatus === '1')) {
        await dispatch(setLinkStatusThunk(editItem!.partner_id, linkStatus));
      }
      await dispatch(getPromoCodesThunk());
      setLoading('fulfilled');
    } catch (error) {
      setLoading('rejected');
    }
    setEditItem(null);
  };

  const data = useMemo(
    () =>
      search
        ? promoCodes.filter(
            (i) =>
              String(i.partner_id).includes(search) ||
              String(i.code).toLowerCase().includes(search.toLowerCase()),
          )
        : promoCodes,
    [promoCodes, search],
  );

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Table
        title="Promo codes"
        columns={[
          {
            key: 'partner_id',
            label: 'Partner ID',
            type: 'alphanumeric',
            width: '93px',
          },
          { key: 'email', label: 'Email', type: 'string', width: '70px' },
          {
            key: 'created_at',
            label: 'Added Date',
            type: 'datetime',
            width: '96px',
          },
          {
            key: 'code',
            label: 'Code Name',
            type: 'alphanumeric',
            width: '94px',
          },
          {
            key: 'link_added',
            label: 'Added to \naf. link',
            type: 'boolean',
            width: '85px',
          },
          {
            key: 'amount',
            label: 'Code Value (EUR)',
            type: 'number',
            width: '97px',
          },
          {
            key: 'exp_date',
            label: 'Expiration date',
            type: 'datetime',
            width: '113px',
          },
          {
            key: 'total_amount',
            label: 'Total Amount (EUR)',
            type: 'number',
            width: '105px',
          },
          {
            key: 'total_count',
            label: 'Total Count',
            type: 'number',
            width: '94px',
          },
        ]}
        data={data}
        rowHeight="62px"
        onEdit={onEdit}
        search={search}
        setSearch={setSearch}
        searchPlaceholder="Code Name, Partner ID"
      />
      <Modal open={!!editItem} onClose={() => setEditItem(null)}>
        <div className={styles.modal}>
          <h2 className={styles.title}>Edit code</h2>
          <p className={styles.text}>
            {`Partner ID: ${editItem?.partner_id}\nCode: ${editItem?.code}`}
          </p>
          <h4 className={styles.label}>
            Promo code is added to affiliate link?
          </h4>
          <RadioButton
            label="NO"
            value="0"
            onChange={setLinkStatus}
            checked={linkStatus}
          />
          <RadioButton
            label="YES"
            value="1"
            onChange={setLinkStatus}
            checked={linkStatus}
          />
          <div className={styles.actions}>
            <Button
              label="Cancel"
              variant="secondary"
              onClick={() => setEditItem(null)}
            />
            <Button label="Save Changes" onClick={onSave} disabled={!confirm} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PromoCodesPage;
