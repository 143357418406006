import React, { useState } from 'react';
import styles from './AuthPage.module.scss';
import { useHistory } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import InputBase from '../../components/InputBase';
import Button from '../../components/Button';
import { logIn } from '../../store/userSlice/userThunk';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import Loader from '../../components/Loader';

const AuthPage: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useLoadingStatus('unknown');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const onSubmit = async () => {
    if (email && password) {
      setLoading('pending');
      try {
        await dispatch(logIn(email, password));
        history.replace('/');
      } catch (error: any) {
        setErrorMessage(`${error?.message}`);
        setLoading('rejected');
      }
    }
  };

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <img src={Logo} className={styles.logo} />
      <div className={styles.form}>
        <InputBase
          label="Email Address"
          value={email}
          onChangeText={setEmail}
          onFocus={() => setErrorMessage('')}
        />
        <InputBase
          label="Password"
          type="password"
          value={password}
          onChangeText={setPassword}
          onFocus={() => setErrorMessage('')}
        />
        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
        <Button label="Log In" onClick={onSubmit} />
      </div>
    </div>
  );
};

export default AuthPage;
