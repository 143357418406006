import { AxiosError } from 'axios';

function catchApiMessage(error: any): Promise<AxiosError> {
  try {
    error.message =
      error.response?.data?.[0] ||
      error.response?.data?.message ||
      error.message ||
      'Something went wrong';
    return Promise.reject(error);
  } catch (error) {
    return Promise.reject(new Error('Something went wrong'));
  }
}

export { catchApiMessage };
