import React, { useEffect, useMemo, useState } from 'react';
import styles from './VouchersPage.module.scss';
import Table from '../../components/Table';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import Loader from '../../components/Loader';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { getVouchersThunk } from '../../store/vouchersSlice/vouchersThunk';

const VouchersPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const vouchers = useSelector((state: RootState) => state.vouchers);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useLoadingStatus('pending');

  useEffect(() => {
    async function init() {
      try {
        await dispatch(getVouchersThunk());
        setLoading('fulfilled');
      } catch (e) {
        setLoading('rejected');
      }
    }

    init();
  }, []);

  const data = useMemo(
    () =>
      search
        ? vouchers.filter(
            (i) =>
              String(i.partner_id).includes(search) ||
              String(i.code).toLowerCase().includes(search.toLowerCase()),
          )
        : vouchers,
    [vouchers, search],
  );

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Table
        title="Vouchers"
        columns={[
          {
            key: 'partner_id',
            label: 'Partner ID',
            type: 'alphanumeric',
          },
          { key: 'creator_email', label: 'Email', type: 'string' },
          {
            key: 'id',
            label: 'Voucher ID',
            type: 'alphanumeric',
          },
          {
            key: 'created_at',
            label: 'Added Date',
            type: 'datetime',
          },
          {
            key: 'code',
            label: 'Voucher Name',
            type: 'alphanumeric',
          },
          {
            key: 'amount',
            label: 'Voucher Value',
            type: 'number',
          },
          {
            key: 'exp_date',
            label: 'Expiration date',
            type: 'datetime',
          },
          {
            key: 'used',
            label: 'Is used',
            type: 'boolean',
          },
        ]}
        data={data}
        rowHeight="48px"
        search={search}
        setSearch={setSearch}
        searchPlaceholder="Voucher Name, Partner ID"
      />
    </div>
  );
};

export default VouchersPage;
