import axios from 'axios';
import * as authApi from './auth';
import * as partnersApi from './partners';
import * as withdrawalsApi from './withdrawals';
import * as transactionsApi from './transactions';
import * as adminsApi from './admins';
import * as promoCodesApi from './promoCodes';
import * as vouchersApi from './vouchers';
import store from '../store';
import { logOut } from '../store/userSlice/userThunk';

axios.defaults.baseURL = 'https://apipartner.yesim.app';
axios.defaults.timeout = 120000;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(logOut());
    }
    return Promise.reject(error);
  },
);

export {
  authApi,
  partnersApi,
  withdrawalsApi,
  transactionsApi,
  adminsApi,
  promoCodesApi,
  vouchersApi,
};
