import React, { useEffect, useRef, useState } from 'react';
import styles from './DateFilter.module.scss';
import DateIcon from '../../assets/icons/ic_date.svg';
import DatePicker from '../DatePicker';
import moment from 'moment';
import { DateRangeType } from '../../hooks/useDateRange';
import Button from '../Button';

const YESTERDAY = moment().subtract(1, 'day');
const PRESETS = [
  {
    text: 'Yesterday',
    start: YESTERDAY,
    end: YESTERDAY,
  },
  {
    text: 'This Week',
    start: moment().startOf('week'),
    end: YESTERDAY,
  },
  {
    text: 'Last Week',
    start: moment().subtract(1, 'week').startOf('week'),
    end: moment().subtract(1, 'week').endOf('week'),
  },
  {
    text: 'This Month',
    start: moment().startOf('month'),
    end: YESTERDAY,
  },
  {
    text: 'Last Month',
    start: moment().subtract(1, 'month').startOf('month'),
    end: moment().subtract(1, 'month').endOf('month'),
  },
  {
    text: 'Last 180 Days',
    start: moment().subtract(6, 'month').startOf('month'),
    end: YESTERDAY,
  },
  {
    text: 'Last 365 Days',
    start: moment().subtract(1, 'year').startOf('year'),
    end: YESTERDAY,
  },
  {
    text: 'All Time',
    start: moment().year(2021).startOf('year'),
    end: YESTERDAY,
  },
];

type DateFilterProps = {
  dateRange: DateRangeType;
  setDateRange: (dateRange: DateRangeType) => void;
};

const DateFilter: React.FC<DateFilterProps> = ({ dateRange, setDateRange }) => {
  const [isOpen, setOpen] = useState(false);
  const containerRef = useRef();
  const [currentDateRange, setCurrentDateRange] =
    useState<DateRangeType>(dateRange);

  function onCancel() {
    setOpen(false);
    setCurrentDateRange(dateRange);
  }

  function onApply() {
    setOpen(false);
    if (
      dateRange.startDate !== currentDateRange.startDate ||
      dateRange.endDate !== currentDateRange.endDate
    ) {
      setDateRange({
        startDate: currentDateRange.startDate,
        endDate:
          currentDateRange.startDate && currentDateRange.endDate === null
            ? YESTERDAY
            : currentDateRange.endDate,
      });
    }
  }

  function handleClick(e: any) {
    if (containerRef && containerRef.current) {
      const ref: any = containerRef.current;
      if (!ref.contains(e.target)) {
        onCancel();
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, []);

  return (
    <div className={styles.container} ref={containerRef as any}>
      <div
        className={styles.field}
        onClick={() => (isOpen ? onCancel() : setOpen(true))}
      >
        <img src={DateIcon} alt="Date" />
        <span className={styles.date}>
          {`${
            currentDateRange.startDate
              ? moment(currentDateRange.startDate).format('DD.MM.YYYY')
              : 'Start Date'
          } – ${
            currentDateRange.endDate
              ? moment(currentDateRange.endDate).format('DD.MM.YYYY')
              : 'End Date'
          }`}
        </span>
      </div>
      {isOpen && (
        <div className={styles.picker}>
          <div className={styles.content}>
            <ul className={styles.presets}>
              {PRESETS.map((item, index) => (
                <li
                  key={index}
                  className={`${styles.item}${
                    moment(item.start).isSame(
                      currentDateRange.startDate,
                      'day',
                    ) &&
                    moment(item.end).isSame(currentDateRange.endDate, 'day')
                      ? ` ${styles.selectedItem}`
                      : ''
                  }`}
                  onClick={() =>
                    setCurrentDateRange({
                      startDate: item.start,
                      endDate: item.end,
                    })
                  }
                >
                  {item.text}
                </li>
              ))}
            </ul>
            <div className={styles.wrapper}>
              <div className={styles.selected}>
                <div className={styles.date}>
                  {currentDateRange.startDate
                    ? moment(currentDateRange.startDate).format('MMM DD, YYYY')
                    : 'Start Date'}
                </div>
                <div className={styles.date}>
                  {currentDateRange.endDate
                    ? moment(currentDateRange.endDate).format('MMM DD, YYYY')
                    : 'End Date'}
                </div>
              </div>
              <DatePicker
                dateRange={currentDateRange}
                setDateRange={setCurrentDateRange}
              />
            </div>
          </div>
          <div className={styles.actions}>
            <Button
              label="Cancel"
              variant="secondary"
              onClick={(e) => {
                e.stopPropagation();
                onCancel();
              }}
            />
            <Button
              label="Apply"
              onClick={(e) => {
                e.stopPropagation();
                onApply();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DateFilter;
