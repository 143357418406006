import { useMemo } from 'react';

export const DOTS = '...';

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
  count,
  page,
}: {
  count: number;
  page: number;
}) => {
  return useMemo(() => {
    if (6 >= count) {
      return range(1, count);
    }

    const leftSiblingIndex = Math.max(page - 5, 1);
    const rightSiblingIndex = Math.min(page + 5, count);
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < count - 2;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * 5;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, count];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * 5;
      const rightRange = range(count - rightItemCount + 1, count);
      return [1, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [1, DOTS, ...middleRange, DOTS, count];
    } else {
      return [];
    }
  }, [count, page]);
};
