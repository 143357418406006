import { CommissionRateType } from '../models/common';

export default (
  commissionRate: string,
  commissionRateType: CommissionRateType,
) => {
  if (commissionRateType === '0') {
    return `${commissionRate} EUR`;
  } else if (commissionRateType === '1') {
    return `${Number(commissionRate)}%`;
  } else {
    return '';
  }
};
