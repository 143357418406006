import React, { useEffect } from 'react';
import styles from './AdminsPage.module.scss';
import Table from '../../components/Table';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import { getAdminsThunk } from '../../store/adminsSlice/adminsThunk';
import Loader from '../../components/Loader';

const AdminsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const admins = useSelector((state: RootState) => state.admins);
  const [loading, setLoading] = useLoadingStatus('pending');

  useEffect(() => {
    async function init() {
      try {
        await dispatch(getAdminsThunk());
        setLoading('fulfilled');
      } catch (e) {
        setLoading('rejected');
      }
    }

    init();
  }, []);

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Table
        title="Admins"
        columns={[
          {
            key: 'createDate',
            label: 'Create Date',
            type: 'datetime',
            width: '310px',
          },
          { key: 'id', label: 'ID', type: 'alphanumeric', width: '310px' },
          { key: 'email', label: 'Email', type: 'string', width: '310px' },
        ]}
        data={admins}
      />
    </div>
  );
};

export default AdminsPage;
