import React, { useEffect, useMemo, useState } from 'react';
import styles from './PartnersInfoPage.module.scss';
import Table from '../../components/Table';
import { Partner } from '../../models/common';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import Loader from '../../components/Loader';
import { getPartnersList } from '../../api/partners';

const PartnersInfoPage: React.FC = () => {
  const [data, setData] = useState<Partner[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useLoadingStatus('pending');

  const onShow = (item: Partner) => {
    window.open(`https://partners.yesim.app/?t=${item.token}`, '_blank');
  };

  useEffect(() => {
    async function init() {
      try {
        setLoading('pending');
        const res = await getPartnersList();
        setData(res);
        setLoading('fulfilled');
      } catch (e) {
        setLoading('rejected');
      }
    }

    init();
  }, []);

  const list = useMemo(
    () =>
      search
        ? data.filter(
            (i) =>
              String(i.id).toLowerCase().includes(search.toLowerCase()) ||
              String(i.email).toLowerCase().includes(search.toLowerCase()) ||
              String(i.link).toLowerCase().includes(search.toLowerCase()),
          )
        : data,
    [data, search],
  );

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Table
        title="Partners Info"
        columns={[
          { key: 'id', label: 'ID', type: 'alphanumeric', width: '90px' },
          { key: 'email', label: 'Email', type: 'string', width: '230px' },
          {
            key: 'balance',
            label: 'Balance',
            type: 'number',
            width: '90px',
          },
          {
            key: 'create_date',
            label: 'Create Date',
            type: 'datetime',
            width: '230px',
          },
          {
            key: 'Name_exp_5',
            label: 'Last Login',
            type: 'datetime',
            width: '230px',
          },
        ]}
        data={list}
        rowHeight="30px"
        onShow={onShow}
        search={search}
        setSearch={setSearch}
        searchPlaceholder="ID, Email"
      />
    </div>
  );
};

export default PartnersInfoPage;
