import React, { useEffect, useMemo, useState } from 'react';
import styles from './PartnersStatsPage.module.scss';
import Table from '../../components/Table';
import { CommissionRateType, PartnerStats } from '../../models/common';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import InputBase from '../../components/InputBase';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import Loader from '../../components/Loader';
import { useDateRange } from '../../hooks/useDateRange';
import Checkbox from '../../components/Checkbox';
import Tooltip from '../../components/Tooltip';
import Select from '../../components/Select';
import { addLink, getPartnersStats, setInterestRate } from '../../api/partners';

const PartnersStatsPage: React.FC = () => {
  const [data, setData] = useState<PartnerStats[]>([]);
  const [partner, setPartner] = useState<PartnerStats | null>(null);
  const [link, setLink] = useState('');
  const commissionRateOptions = [
    { label: '%', value: '1' },
    { label: 'EUR', value: '0' },
  ];
  const [firstRate, setFirstRate] = useState('');
  const [firstRateType, setFirstRateType] = useState<CommissionRateType>('1');
  const [secondRate, setSecondRate] = useState('');
  const [secondRateType, setSecondRateType] = useState<CommissionRateType>('0');
  const [recurringRate, setRecurringRate] = useState('');
  const [recurringRateType, setRecurringRateType] =
    useState<CommissionRateType>('0');
  const [search, setSearch] = useState('');
  const { dateRange, setDateRange } = useDateRange();
  const [loading, setLoading] = useLoadingStatus('pending');
  const [confirm, setConfirm] = useState(false);
  const firstRateError = Number(firstRate) <= 0;
  const secondRateError = Number(secondRate) <= 0;
  const recurringRateError = Number(recurringRate) <= 0;

  const onEdit = (item: PartnerStats) => {
    setLink(item.link);
    setFirstRate(item.first_commission);
    setFirstRateType(item.first_commission_type);
    setSecondRate(item['2nd_commission']);
    setSecondRateType(item['2nd_commission_type']);
    setRecurringRate(item.recurring_commission);
    setRecurringRateType(item.recurring_commission_type);
    setPartner(item);
  };

  const onShow = (item: PartnerStats) => {
    window.open(`https://partners.yesim.app/?t=${item.token}`, '_blank');
  };

  const onSave = async () => {
    setLoading('pending');
    try {
      if (partner!.link !== link) {
        await addLink(partner!.id, link);
      }
      if (
        partner!.first_commission !== firstRate ||
        partner!.first_commission_type !== firstRateType
      ) {
        await setInterestRate(
          partner!.id,
          firstRate,
          'first_commission',
          firstRateType,
        );
      }
      if (
        partner!['2nd_commission'] !== secondRate ||
        partner!['2nd_commission_type'] !== secondRateType
      ) {
        await setInterestRate(
          partner!.id,
          secondRate,
          '2nd_commission',
          secondRateType,
        );
      }
      if (
        partner!.recurring_commission !== recurringRate ||
        partner!.recurring_commission_type !== recurringRateType
      ) {
        await setInterestRate(
          partner!.id,
          recurringRate,
          'recurring_commission',
          recurringRateType,
        );
      }
      const res = await getPartnersStats(dateRange);
      setData(res);
      setLoading('fulfilled');
    } catch (error) {
      setLoading('rejected');
    }
    setPartner(null);
  };

  useEffect(() => {
    async function init() {
      try {
        setLoading('pending');
        const res = await getPartnersStats(dateRange);
        setData(res);
        setLoading('fulfilled');
      } catch (e) {
        setLoading('rejected');
      }
    }

    if (dateRange.startDate && dateRange.endDate) {
      init();
    }
  }, [dateRange]);

  const list = useMemo(
    () =>
      search
        ? data.filter(
            (i) =>
              String(i.id).toLowerCase().includes(search.toLowerCase()) ||
              String(i.email).toLowerCase().includes(search.toLowerCase()) ||
              String(i.link).toLowerCase().includes(search.toLowerCase()),
          )
        : data,
    [data, search],
  );

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Table
        title="Partners Stats"
        columns={[
          { key: 'id', label: 'ID', type: 'alphanumeric', width: '70px' },
          { key: 'email', label: 'Email', type: 'string', width: '120px' },
          {
            key: 'total_revenue',
            label: 'Total\nRev.',
            type: 'number',
            width: '70px',
            hint: 'Amount of money partner generates from promoting Yesim over a defined period of time',
          },
          {
            key: 'averege_refill',
            label: 'Av.\nRefill',
            type: 'number',
            width: '70px',
            hint: 'Average amount of money customers spend on a every Yesim order over a defined period of time',
          },
          {
            key: 'earned',
            label: 'Earned',
            type: 'number',
            width: '70px',
          },
          {
            key: 'total_clicks',
            label: 'Clicks',
            type: 'number',
            width: '70px',
          },
          {
            key: 'total_installs',
            label: 'Installs',
            type: 'number',
            width: '70px',
          },
          {
            key: 'total_purchases',
            label: 'Orders',
            type: 'number',
            width: '90px',
          },
          {
            key: 'recurring_commission',
            rateTypeKey: 'recurring_commission_type',
            label: 'Recur.',
            type: 'number',
            width: '70px',
          },
          {
            key: 'first_commission',
            rateTypeKey: 'first_commission_type',
            label: '1st P.',
            type: 'number',
            width: '70px',
          },
          {
            key: '2nd_commission',
            rateTypeKey: '2nd_commission_type',
            label: '2nd P.',
            type: 'number',
            width: '70px',
          },
          {
            key: 'last_order_date',
            label: 'Last Order',
            type: 'datetime',
            width: '120px',
          },
        ]}
        data={list}
        rowHeight="44px"
        onEdit={onEdit}
        onShow={onShow}
        search={search}
        setSearch={setSearch}
        searchPlaceholder="ID, Email, Link"
        dateRange={dateRange}
        setDateRange={setDateRange}
        hint="Time period for Total Revenue, Average Refill, Clicks, Installs, Orders"
      />
      <Modal open={!!partner} onClose={() => setPartner(null)}>
        <div className={styles.modal}>
          <h2 className={styles.title}>Edit partner</h2>
          <p className={styles.text}>
            {`Partner ID: ${partner?.id} \nPartner Email: ${partner?.email}`}
          </p>
          <InputBase label="Link" value={link || ''} onChangeText={setLink} />
          <InputBase
            label="Commission Rate for the 1st Purchase"
            value={firstRate}
            onChangeText={(value) => {
              if (isNaN(+value)) return;
              setFirstRate(value);
            }}
            placeholder="Not set"
            startAdornment={
              <Select
                value={firstRateType}
                options={commissionRateOptions}
                onChange={(value) =>
                  setFirstRateType(value as CommissionRateType)
                }
              />
            }
            error={firstRateError}
            errorText="This is required field"
          />
          <InputBase
            label="Commission Rate for the 2nd Purchase"
            value={secondRate}
            onChangeText={(value) => {
              if (isNaN(+value)) return;
              setSecondRate(value);
            }}
            placeholder="Not set"
            startAdornment={
              <Select
                value={secondRateType}
                options={commissionRateOptions}
                onChange={(value) =>
                  setSecondRateType(value as CommissionRateType)
                }
              />
            }
            error={secondRateError}
            errorText="This is required field"
          />
          <InputBase
            label={
              <>
                Recurring Commission Rate
                <Tooltip
                  content={
                    'The partner will receive commissions for every purchase made by the customer.\n\nThis rate does not apply to their first and second purchase (if the corresponding commission is set).'
                  }
                />
              </>
            }
            value={recurringRate}
            onChangeText={(value) => {
              if (isNaN(+value)) return;
              setRecurringRate(value);
            }}
            placeholder="Not set"
            startAdornment={
              <Select
                value={recurringRateType}
                options={commissionRateOptions}
                onChange={(value) =>
                  setRecurringRateType(value as CommissionRateType)
                }
              />
            }
            error={recurringRateError}
            errorText="This is required field"
          />
          <Checkbox
            onChange={() => setConfirm(!confirm)}
            checked={confirm}
            label={'I confirm changes'}
            className={styles.confirm}
          />
          <div className={styles.actions}>
            <Button
              label="Cancel"
              variant="secondary"
              onClick={() => setPartner(null)}
            />
            <Button
              label="Save Changes"
              onClick={onSave}
              disabled={
                !confirm ||
                firstRateError ||
                secondRateError ||
                recurringRateError
              }
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PartnersStatsPage;
