import axios from 'axios';
import { getAuthKey } from '../store';
import { catchApiMessage } from '../utils/catchApiMessage';
import { DateRangeType } from '../hooks/useDateRange';
import { CommissionRateType } from '../models/common';

export const getPartners = (dateRange?: DateRangeType) => {
  const from = dateRange?.startDate?.format('YYYY-MM-DD');
  const to = dateRange?.endDate?.format('YYYY-MM-DD');
  return axios
    .get('/partners_clicks', {
      params: {
        token: getAuthKey(),
        from,
        to,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);
};

export const getPartnersList = () => {
  return axios
    .get('/partners_list', {
      params: {
        token: getAuthKey(),
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);
};

export const getPartnersStats = (dateRange?: DateRangeType) => {
  const from = dateRange?.startDate?.format('YYYY-MM-DD');
  const to = dateRange?.endDate?.format('YYYY-MM-DD');
  return axios
    .get('/partners_clicks2', {
      params: {
        token: getAuthKey(),
        from,
        to,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);
};

export const addLink = (partner_id: string, link: string) =>
  axios
    .post('/add_link', null, {
      params: {
        token: getAuthKey(),
        partner_id,
        link,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const setInterestRate = (
  partner_id: string,
  rate: string,
  type: 'first_commission' | '2nd_commission' | 'recurring_commission' | number,
  rate_type: CommissionRateType,
) =>
  axios
    .post('/set_interest_rate', null, {
      params: {
        token: getAuthKey(),
        partner_id,
        rate,
        type,
        rate_type,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);
