import React, { InputHTMLAttributes } from 'react';
import styles from './InputBase.module.scss';
import ErrorIcon from '../../assets/icons/ic_error.svg';

type InputBaseProps = InputHTMLAttributes<HTMLInputElement> & {
  onChangeText?: (value: string) => void;
  label?: string | React.ReactNode;
  startAdornment?: React.ReactNode;
  error?: boolean;
  errorText?: string;
};

const InputBase: React.FC<InputBaseProps> = ({
  onChangeText,
  label,
  startAdornment,
  error,
  errorText,
  ...props
}) => {
  return (
    <div className={styles.container}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.field}>
        {startAdornment && (
          <div className={styles.startAdornment}>{startAdornment}</div>
        )}
        <input
          className={`${styles.input}${error ? ` ${styles.error}` : ''}`}
          onChange={(e) => onChangeText && onChangeText(e.target.value)}
          {...props}
        />
        {error && (
          <img src={ErrorIcon} alt="Error" className={styles.errorIcon} />
        )}
      </div>
      {error && errorText && <p className={styles.errorText}>{errorText}</p>}
    </div>
  );
};

export default InputBase;
