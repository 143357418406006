import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Admin } from '../../models/common';
import { userActions } from '../userSlice/userSlice';

const initialState: Admin[] = [];

const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    saveAdmins: (state, action: PayloadAction<Admin[]>) => {
      return action.payload;
    },
  },
  extraReducers: {
    [userActions.logOut.type]: () => initialState,
  },
});

export const adminsActions = adminsSlice.actions;

export default adminsSlice.reducer;
