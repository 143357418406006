import React from 'react';
import styles from './Tooltip.module.scss';
import TooltipIcon from '../../assets/icons/ic_tooltip.svg';

type TooltipProps = {
  content: string;
  className?: string;
  children?: React.ReactNode;
};

const Tooltip: React.FC<TooltipProps> = ({ children, content, className }) => {
  return (
    <div className={`${styles.container}${className ? ` ${className}` : ''}`}>
      {children ? (
        children
      ) : (
        <img src={TooltipIcon} alt="Tooltip" className={styles.icon} />
      )}
      <span className={styles.content}>{content}</span>
    </div>
  );
};

export default Tooltip;
