import { configureStore, Action, combineReducers } from '@reduxjs/toolkit';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import userReducer from './userSlice/userSlice';
import adminsSlice from './adminsSlice/adminsSlice';
import transactionsSlice from './transactionsSlice/transactionsSlice';
import withdrawalsSlice from './withdrawalsSlice/withdrawalsSlice';
import promoCodesSlice from './promoCodesSlice/promoCodesSlice';
import vouchersSlice from './vouchersSlice/vouchersSlice';

const rootReducer = combineReducers({
  user: userReducer,
  admins: adminsSlice,
  transactions: transactionsSlice,
  withdrawals: withdrawalsSlice,
  promoCodes: promoCodesSlice,
  vouchers: vouchersSlice,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
export const getAuthKey = () => store.getState().user.token;

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;
export type AppThunkDispatch = ThunkDispatch<RootState, any, Action<any>>;

export type AppThunk<R = Promise<any>> = ThunkAction<
  R,
  RootState,
  unknown,
  Action<string>
>;
