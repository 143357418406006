import { promoCodesApi } from '../../api';
import { promoCodesActions } from './promoCodesSlice';
import { AppThunk } from '..';

export const getPromoCodesThunk = (): AppThunk => async (dispatch) => {
  const response = await promoCodesApi.getPromoCodes();
  dispatch(promoCodesActions.savePromoCodes(response));
};

export const setLinkStatusThunk =
  (payment_id: string, status: string): AppThunk =>
  async (dispatch) => {
    await promoCodesApi.setLinkStatus(payment_id, status);
    await dispatch(getPromoCodesThunk());
  };
