import React, { useEffect, useMemo, useState } from 'react';
import styles from './WithdrawalsPage.module.scss';
import Table from '../../components/Table';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import Modal from '../../components/Modal';
import Button from '../../components/Button';
import { Withdrawal } from '../../models/common';
import RadioButton from '../../components/RadioButton';
import Loader from '../../components/Loader';
import {
  getWithdrawalsThunk,
  setWithdrawalStatusThunk,
} from '../../store/withdrawalsSlice/withdrawalsThunk';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import moment from 'moment';
import { useDateRange } from '../../hooks/useDateRange';

const WithdrawalsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const withdrawals = useSelector((state: RootState) => state.withdrawals);
  const [withdrawal, setWithdrawal] = useState<Withdrawal | null>(null);
  const [status, setStatus] = useState('0');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useLoadingStatus('pending');
  const { dateRange, setDateRange } = useDateRange();

  useEffect(() => {
    async function init() {
      try {
        await dispatch(getWithdrawalsThunk());
        setLoading('fulfilled');
      } catch (e) {
        setLoading('rejected');
      }
    }

    init();
  }, []);

  const onEdit = (item: Withdrawal) => {
    setStatus(
      {
        PENDING: '0',
        PROCESSED: '1',
        REJECTED: '2',
      }[item.status] || item.status,
    );
    setWithdrawal(item);
  };

  const onSave = async () => {
    if (withdrawal?.paymentId) {
      setLoading('pending');
      try {
        await dispatch(setWithdrawalStatusThunk(withdrawal.paymentId, status));
        setLoading('fulfilled');
      } catch (error) {
        setLoading('rejected');
      }
    }
    setWithdrawal(null);
  };

  const list = useMemo(
    () =>
      dateRange.startDate && dateRange.endDate
        ? withdrawals.filter((i) =>
            moment(i.requestDate)
              .startOf('day')
              .isBetween(dateRange.startDate, dateRange.endDate, 'day', '[]'),
          )
        : withdrawals,
    [withdrawals, dateRange],
  );

  const data = useMemo(
    () =>
      search
        ? list.filter(
            (i) =>
              String(i.partnerId)
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              String(i.paymentId)
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              String(i.partnerEmail)
                .toLowerCase()
                .includes(search.toLowerCase()),
          )
        : list,
    [list, search],
  );

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Table
        title="Withdrawal Requests"
        columns={[
          {
            key: 'requestDate',
            label: 'Request Date',
            type: 'datetime',
            width: '140px',
          },
          {
            key: 'withdrawalId',
            label: 'Withdrawal ID',
            type: 'alphanumeric',
            width: '140px',
          },
          {
            key: 'partnerId',
            label: 'Partner ID',
            type: 'alphanumeric',
            width: '140px',
          },
          {
            key: 'partnerEmail',
            label: 'Partner e-mail',
            type: 'string',
            width: '140px',
          },
          {
            key: 'amount',
            label: 'Amount',
            type: 'number',
            width: '140px',
          },
          {
            key: 'status',
            label: 'Status',
            type: 'string',
            width: '140px',
          },
        ]}
        data={data}
        rowHeight="62px"
        onEdit={onEdit}
        search={search}
        setSearch={setSearch}
        searchPlaceholder="ID, e-mail"
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <Modal open={!!withdrawal} onClose={() => setWithdrawal(null)}>
        <div className={styles.modal}>
          <h2 className={styles.title}>Edit withdrawal status</h2>
          <p className={styles.content}>
            {`Withdrawal ID: ${withdrawal?.withdrawalId} \nPartner Email: ${withdrawal?.partnerEmail}`}
          </p>
          <RadioButton
            label="REQUESTED"
            value="0"
            onChange={setStatus}
            checked={status}
          />
          <RadioButton
            label="PROCESSED"
            value="1"
            onChange={setStatus}
            checked={status}
          />
          <RadioButton
            label="CANCELLED"
            value="2"
            onChange={setStatus}
            checked={status}
          />
          <div className={styles.actions}>
            <Button
              label="Cancel"
              variant="secondary"
              onClick={() => setWithdrawal(null)}
            />
            <Button label="Save Changes" onClick={onSave} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default WithdrawalsPage;
