import React from 'react';
import styles from './AppNav.module.scss';
import { NavLink } from 'react-router-dom';

const AppNav: React.FC = () => {
  return (
    <nav className={styles.container}>
      <NavLink
        to="/partners-info"
        className={styles.navLink}
        activeClassName={styles.active}
      >
        Partners Info
      </NavLink>
      <NavLink
        to="/partners-stats"
        className={styles.navLink}
        activeClassName={styles.active}
      >
        Partners Stats
      </NavLink>
      <NavLink
        to="/withdrawals"
        className={styles.navLink}
        activeClassName={styles.active}
      >
        Withdrawal Requests
      </NavLink>
      <NavLink
        to="/transactions"
        className={styles.navLink}
        activeClassName={styles.active}
      >
        Transactions
      </NavLink>
      <NavLink
        to="/promo-codes"
        className={styles.navLink}
        activeClassName={styles.active}
      >
        Promo codes
      </NavLink>
      <NavLink
        to="/vouchers"
        className={styles.navLink}
        activeClassName={styles.active}
      >
        Vouchers
      </NavLink>
      <div className={styles.divider} />
      <NavLink
        to="/admins"
        className={styles.navLink}
        activeClassName={styles.active}
      >
        Admins
      </NavLink>
    </nav>
  );
};

export default AppNav;
