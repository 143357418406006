import React from 'react';
import styles from './Modal.module.scss';

type ModalProps = {
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
};

const Modal: React.FC<ModalProps> = ({ children, open, onClose }) => {
  if (!open) return null;

  return (
    <div
      className={styles.container}
      onClick={(e) => {
        e.stopPropagation();
        onClose?.();
      }}
    >
      <div
        className={styles.content}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
