import { useState } from 'react';
import moment from 'moment';

export type DateRangeType = {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
};

export const useDateRange = (initDates?: DateRangeType) => {
  const [dateRange, setDateRange] = useState<DateRangeType>(
    initDates || {
      startDate: moment().startOf('month'),
      endDate: moment().subtract(1, 'day'),
    },
  );

  return { dateRange, setDateRange };
};
