import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PromoCode } from '../../models/common';
import { userActions } from '../userSlice/userSlice';

const initialState: PromoCode[] = [];

const promoCodesSlice = createSlice({
  name: 'promoCodes',
  initialState,
  reducers: {
    savePromoCodes: (state, action: PayloadAction<PromoCode[]>) => {
      return action.payload;
    },
  },
  extraReducers: {
    [userActions.logOut.type]: () => initialState,
  },
});

export const promoCodesActions = promoCodesSlice.actions;

export default promoCodesSlice.reducer;
