import React, { useEffect, useMemo, useState } from 'react';
import styles from './TransactionsPage.module.scss';
import Table from '../../components/Table';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import moment from 'moment';
import { useDateRange } from '../../hooks/useDateRange';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import Loader from '../../components/Loader';
import useLoadingStatus from '../../hooks/useLoadingStatus';
import { getTransactionsThunk } from '../../store/transactionsSlice/transactionsThunk';

const TransactionsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const transactions = useSelector((state: RootState) => state.transactions);
  const [search, setSearch] = useState('');
  const { dateRange, setDateRange } = useDateRange();
  const [loading, setLoading] = useLoadingStatus('pending');

  useEffect(() => {
    async function init() {
      try {
        await dispatch(getTransactionsThunk());
        setLoading('fulfilled');
      } catch (e) {
        setLoading('rejected');
      }
    }

    init();
  }, []);

  const list = useMemo(
    () =>
      dateRange.startDate && dateRange.endDate
        ? transactions.filter((i) =>
            moment(i.createdDate)
              .startOf('day')
              .isBetween(dateRange.startDate, dateRange.endDate, 'day', '[]'),
          )
        : transactions,
    [transactions, dateRange],
  );

  const data = useMemo(
    () =>
      search
        ? list.filter(
            (i) =>
              String(i.purchasedId)
                .toLowerCase()
                .includes(search.toLowerCase()) ||
              String(i.customerId).toLowerCase().includes(search.toLowerCase()),
          )
        : list,
    [list, search],
  );

  if (loading === 'pending') {
    return (
      <div className="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <Table
        title="Transactions"
        columns={[
          {
            key: 'createdDate',
            label: 'Create Date',
            type: 'datetime',
            width: '188px',
          },
          {
            key: 'purchasedId',
            label: 'Purchase ID',
            type: 'alphanumeric',
            width: '188px',
          },
          {
            key: 'customerId',
            label: 'Customer ID',
            type: 'alphanumeric',
            width: '188px',
          },
          {
            key: 'purchaseAmount',
            label: 'Purchase Amount',
            type: 'number',
            width: '188px',
          },
          {
            key: 'commissionAmount',
            label: 'Commission Amount',
            type: 'number',
            width: '188px',
          },
          {
            key: 'type',
            label: 'Type',
            type: 'string',
            width: '188px',
          },
          {
            key: 'partner_source',
            label: 'Source',
            type: 'string',
            width: '188px',
          },
        ]}
        data={data}
        rowHeight="48px"
        search={search}
        setSearch={setSearch}
        searchPlaceholder="Purchase ID, Customer ID"
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
    </div>
  );
};

export default TransactionsPage;
