import axios from 'axios';
import { getAuthKey } from '../store';
import { catchApiMessage } from '../utils/catchApiMessage';

export const getVouchers = () =>
  axios
    .post('/voucher_get_admin', null, {
      params: {
        token: getAuthKey(),
      },
    })
    .then((res) => res?.data?.message)
    .catch(catchApiMessage);
