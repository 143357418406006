import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Voucher } from '../../models/common';
import { userActions } from '../userSlice/userSlice';

const initialState: Voucher[] = [];

const vouchersSlice = createSlice({
  name: 'vouchers',
  initialState,
  reducers: {
    saveVouchers: (state, action: PayloadAction<Voucher[]>) => {
      return action.payload;
    },
  },
  extraReducers: {
    [userActions.logOut.type]: () => initialState,
  },
});

export const vouchersActions = vouchersSlice.actions;

export default vouchersSlice.reducer;
