import React from 'react';
import styles from './Checkbox.module.scss';
import CheckedIcon from '../../assets/icons/ic_checked.svg';
import UncheckedIcon from '../../assets/icons/ic_unchecked.svg';

type CheckboxProps = {
  checked: boolean;
  onChange: (p: boolean) => void;
  label?: string;
  className?: string;
};

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  onChange,
  label,
  className,
}) => {
  return (
    <div
      className={`${styles.container} ${className}`}
      onClick={() => onChange(!checked)}
    >
      <img
        src={checked ? CheckedIcon : UncheckedIcon}
        alt="Checkbox"
        className={styles.icon}
      />
      {label && <span className={styles.label}>{label}</span>}
    </div>
  );
};

export default Checkbox;
