import React from 'react';
import styles from './RadioButton.module.scss';

type RadioButtonProps = {
  label: string;
  value: string;
  onChange: (e: string) => void;
  checked: string;
};

const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  value,
  onChange,
  checked,
}) => (
  <div className={styles.container} onClick={() => onChange(value)}>
    <div className={styles.radioOuterCircle}>
      {checked === value && <div className={styles.radioInnerCircle} />}
    </div>
    <span className={styles.label}>{label}</span>
  </div>
);

export default RadioButton;
