import React from 'react';
import styles from './Pagination.module.scss';
import NavigateBeforeIcon from '../../assets/icons/ic_navigate_before.svg';
import NavigateNextIcon from '../../assets/icons/ic_navigate_next.svg';
import { DOTS, usePagination } from '../../hooks/usePagination';

type PaginationProps = {
  page: number;
  rowsPerPage: 10 | 20 | 50 | 'ALL';
  count: number;
  onChange: (page: number) => void;
  onChangeRowsPerPage: (rowsPerPage: 10 | 20 | 50 | 'ALL') => void;
};

const Pagination: React.FC<PaginationProps> = ({
  page,
  rowsPerPage,
  count,
  onChange,
  onChangeRowsPerPage,
}) => {
  const pages = usePagination({
    page,
    count,
  });

  return (
    <div className={styles.container}>
      <span className={styles.showOnPage}>Show on page</span>
      <ul className={styles.rowsPerPage}>
        <li
          className={`${styles.amount} ${
            rowsPerPage === 10 && styles.selected
          }`}
          onClick={() => {
            onChangeRowsPerPage(10);
            onChange(1);
          }}
        >
          10
        </li>
        <li
          className={`${styles.amount} ${
            rowsPerPage === 20 && styles.selected
          }`}
          onClick={() => {
            onChangeRowsPerPage(20);
            onChange(1);
          }}
        >
          20
        </li>
        <li
          className={`${styles.amount} ${
            rowsPerPage === 50 && styles.selected
          }`}
          onClick={() => {
            onChangeRowsPerPage(50);
            onChange(1);
          }}
        >
          50
        </li>
        <li
          className={`${styles.amount} ${
            rowsPerPage === 'ALL' && styles.selected
          }`}
          onClick={() => {
            onChangeRowsPerPage('ALL');
            onChange(1);
          }}
        >
          All
        </li>
      </ul>
      <ul className={styles.pagination}>
        <img
          src={NavigateBeforeIcon}
          alt="Navigate Before"
          onClick={() => onChange(page > 1 ? page - 1 : 1)}
          className={styles.icon}
        />
        {pages.map((item, index) => {
          if (item === DOTS) {
            return (
              <li key={index} className={styles.dots}>
                {item}
              </li>
            );
          }

          return (
            <li
              key={index}
              className={`${styles.number} ${page === item && styles.selected}`}
              onClick={() => onChange(item as number)}
            >
              {item}
            </li>
          );
        })}
        <img
          src={NavigateNextIcon}
          alt="Navigate Next"
          onClick={() => onChange(page < count ? page + 1 : count)}
          className={styles.icon}
        />
      </ul>
    </div>
  );
};

export default Pagination;
