import React, { useState } from 'react';
import styles from './Table.module.scss';
import SortIcon from '../../assets/icons/ic_sort.svg';
import SortDescendingIcon from '../../assets/icons/ic_sort_descending.svg';
import SortAscendingIcon from '../../assets/icons/ic_sort_ascending.svg';
import SearchInput from '../SearchInput';
import Pagination from '../Pagination';
import DateFilter from '../DateFilter';
import sortList from '../../utils/sortList';
import { DateRangeType } from '../../hooks/useDateRange';
import Tooltip from '../Tooltip';
import formatCommissionRate from '../../utils/formatCommissionRate';
import { CommissionRateType } from '../../models/common';

type TableProps = {
  title: string;
  columns: {
    key: string;
    label: string;
    type?: 'alphanumeric' | 'datetime' | 'string' | 'number' | 'boolean';
    width?: string;
    hint?: string;
    rateTypeKey?: string;
  }[];
  data: { [key: string]: string | number | null | boolean }[];
  rowHeight?: string;
  onEdit?: (item: any) => void;
  onShow?: (item: any) => void;
  search?: string;
  setSearch?: (term: string) => void;
  searchPlaceholder?: string;
  dateRange?: DateRangeType;
  setDateRange?: (dateRange: DateRangeType) => void;
  hint?: string;
};

const Table: React.FC<TableProps> = ({
  title,
  columns,
  data,
  rowHeight,
  onEdit,
  onShow,
  search,
  setSearch,
  searchPlaceholder,
  dateRange,
  setDateRange,
  hint,
}) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState<10 | 20 | 50 | 'ALL'>(10);
  const [sort, setSort] = useState<{
    key: string;
    isSortedDesc?: boolean;
    type?: 'string' | 'number' | 'alphanumeric' | 'datetime' | 'boolean';
  }>({
    key: '',
    isSortedDesc: true,
    type: 'datetime',
  });

  const count = data.length;
  const endIndex = rowsPerPage === 'ALL' ? page * count : page * rowsPerPage;
  const startIndex =
    rowsPerPage === 'ALL' ? endIndex - count : endIndex - rowsPerPage;

  const list =
    sort.key && sort.type
      ? sortList(data, sort.key, sort.type, sort.isSortedDesc)
      : data;

  const rows = list.slice(startIndex, endIndex);

  const onClearFilters = () => {
    !!setSearch && setSearch('');
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.top}>
          <span className={styles.title}>{title}</span>
          {hint && <Tooltip content={hint} className={styles.tooltip} />}
          {!!dateRange && !!setDateRange && (
            <DateFilter
              dateRange={dateRange}
              setDateRange={(dateRange) => {
                setDateRange(dateRange);
                setPage(1);
              }}
            />
          )}
          {!!setSearch && (
            <>
              <SearchInput
                value={search!}
                onChangeText={(term) => {
                  setSearch(term);
                  setPage(1);
                }}
                placeholder={searchPlaceholder}
              />
              <span
                className={`${styles.clear} ${!!search && styles.visible}`}
                onClick={onClearFilters}
              >
                Clear Filters
              </span>
            </>
          )}
        </div>
        <div className={styles.columns}>
          {columns.map((item, index) => (
            <div
              key={index}
              className={styles.column}
              onClick={() => {
                setSort({
                  key: item.key,
                  isSortedDesc: sort.key !== item.key || !sort.isSortedDesc,
                  type: item.type,
                });
              }}
              style={{ width: item.width || '100%' }}
            >
              <div
                className={`${styles.item} ${
                  item.key === sort.key && styles.sorted
                }`}
              >
                {item.hint && (
                  <Tooltip content={item.hint} className={styles.tooltip} />
                )}
                <span
                  className={styles.label}
                  style={{
                    maxWidth: item.width
                      ? `calc(${item.width} - ${item.hint ? '50px' : '30px'})`
                      : '100%',
                  }}
                >
                  {item.label}
                </span>
                <img
                  src={
                    item.key === sort.key
                      ? sort.isSortedDesc
                        ? SortDescendingIcon
                        : SortAscendingIcon
                      : SortIcon
                  }
                  alt="Sort"
                />
              </div>
            </div>
          ))}
          {(!!onEdit || !!onShow) && (
            <span className={styles.actionsColumn}>Actions</span>
          )}
        </div>
      </div>
      <div className={styles.rows}>
        {rows.map((item, index) => (
          <div
            key={index}
            className={styles.row}
            style={{ height: rowHeight || '34px' }}
          >
            {columns.map(({ key, width, type, rateTypeKey }) => (
              <div
                key={key}
                className={styles.cell}
                style={{ width: width || '100%' }}
              >
                {type === 'boolean'
                  ? item[key] && item[key] !== '0'
                    ? 'YES'
                    : 'NO'
                  : `${
                      rateTypeKey
                        ? formatCommissionRate(
                            item[key] as string,
                            item[rateTypeKey] as CommissionRateType,
                          )
                        : item[key] || '---'
                    }`}
              </div>
            ))}
            {(!!onEdit || !!onShow) && (
              <div className={styles.actions}>
                {!!onEdit && (
                  <p className={styles.action} onClick={() => onEdit(item)}>
                    Edit
                  </p>
                )}
                {!!onShow && (
                  <p className={styles.action} onClick={() => onShow(item)}>
                    View
                  </p>
                )}
              </div>
            )}
          </div>
        ))}
        {count === 0 && (
          <span className={styles.empty}>
            {!!search
              ? 'Nothing found'
              : 'Not enough data or not all actions have been processed'}
          </span>
        )}
      </div>
      <div className={styles.footer}>
        <Pagination
          page={page}
          rowsPerPage={rowsPerPage}
          count={
            rowsPerPage === 'ALL' ? 1 : Math.ceil(count / rowsPerPage) || 1
          }
          onChange={setPage}
          onChangeRowsPerPage={setRowsPerPage}
        />
      </div>
    </div>
  );
};

export default Table;
