import { authApi } from '../../api';
import { userActions } from './userSlice';
import { AppThunk } from '../index';

export const logIn =
  (email: string, password: string): AppThunk =>
  async (dispatch) => {
    try {
      const res = await authApi.logIn(email, password);
      dispatch(userActions.saveUser({ token: res.token, email }));
      localStorage.setItem('token', res.token);
      localStorage.setItem('email', email);
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const checkToken = (): AppThunk => async (dispatch) => {
  const token = localStorage.getItem('token');
  const email = localStorage.getItem('email');
  if (token && email) {
    dispatch(userActions.saveUser({ token, email }));
    return true;
  }
  return false;
};

export const logOut = (): AppThunk => async (dispatch) => {
  dispatch(userActions.logOut());
  localStorage.clear();
};
