import moment from 'moment';

export default (
  list: { [key: string]: string | number | null | boolean }[],
  sortBy: string,
  type?: 'alphanumeric' | 'datetime' | 'string' | 'number' | 'boolean',
  isSortedDesc?: boolean,
) => {
  switch (type) {
    case 'alphanumeric':
      return [...list].sort((a, b) =>
        isSortedDesc
          ? String(b[sortBy])
              .toLowerCase()
              .localeCompare(String(a[sortBy]), 'en', {
                numeric: true,
              })
          : String(a[sortBy])
              .toLowerCase()
              .localeCompare(String(b[sortBy]), 'en', {
                numeric: true,
              }),
      );
    case 'datetime':
      return [...list].sort((a, b) => {
        if (a[sortBy] === null) {
          return 1;
        } else if (b[sortBy] === null) {
          return -1;
        }
        return isSortedDesc
          ? moment(b[sortBy] as string).diff(a[sortBy] as string)
          : moment(a[sortBy] as string).diff(b[sortBy] as string);
      });
    case 'string':
      return [...list].sort((a, b) =>
        isSortedDesc
          ? String(b[sortBy]).toLowerCase().localeCompare(String(a[sortBy]))
          : String(a[sortBy]).toLowerCase().localeCompare(String(b[sortBy])),
      );
    case 'number':
      return [...list].sort((a, b) =>
        isSortedDesc
          ? Number(b[sortBy]) - Number(a[sortBy])
          : Number(a[sortBy]) - Number(b[sortBy]),
      );
    case 'boolean':
      return [...list].sort((a, b) =>
        isSortedDesc
          ? Number(b[sortBy]) - Number(a[sortBy])
          : Number(a[sortBy]) - Number(b[sortBy]),
      );
    default:
      return list;
  }
};
