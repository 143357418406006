import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../models/common';

const initialState: User = {
  email: '',
  token: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser: (state, action: PayloadAction<User>) => {
      return action.payload;
    },
    logOut: () => initialState,
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
