import axios from 'axios';
import { getAuthKey } from '../store';
import { catchApiMessage } from '../utils/catchApiMessage';

export const getWithdrawals = () =>
  axios
    .get('/withdrawals', {
      params: {
        token: getAuthKey(),
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);

export const setWithdrawalStatus = (payment_id: string, status: string) =>
  axios
    .post('/set_withdrawal_status', null, {
      params: {
        token: getAuthKey(),
        payment_id,
        status,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);
