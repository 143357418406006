import axios from 'axios';
import { catchApiMessage } from '../utils/catchApiMessage';

export const logIn = (email: string, password: string) =>
  axios
    .post('/login', null, {
      params: {
        email,
        password,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);
