import React, { useEffect, useRef, useState } from 'react';
import styles from './Select.module.scss';
import ExpandMoreIcon from '../../assets/icons/ic_expand_more.svg';
import ExpandLessIcon from '../../assets/icons/ic_expand_less.svg';

type SelectProps = {
  value: string;
  options: { label: string; value: string }[];
  onChange?: (value: string) => void;
  disabled?: boolean;
};

const Select: React.FC<SelectProps> = ({
  value,
  options,
  onChange,
  disabled,
}) => {
  const [isOpen, setOpen] = useState(false);
  const selectRef = useRef();
  const label = options?.find((i) => i.value === value)?.label;

  useEffect(() => {
    function handleClick(e: any) {
      if (selectRef && selectRef.current) {
        const ref: any = selectRef.current;
        if (!ref.contains(e.target)) {
          setOpen(false);
        }
      }
    }

    document.addEventListener('click', handleClick);

    return () => document.removeEventListener('click', handleClick);
  }, []);

  function onSelect(value: string) {
    onChange?.(value);
    setOpen(false);
  }

  return (
    <div
      className={`${styles.select}${disabled ? ` ${styles.disabled}` : ''}`}
      ref={selectRef as any}
    >
      <div
        className={styles.input}
        onClick={() => !disabled && setOpen(!isOpen)}
      >
        <span className={styles.label}>{label}</span>
        <img
          src={isOpen ? ExpandLessIcon : ExpandMoreIcon}
          alt="Expand"
          className={styles.icon}
        />
      </div>
      {isOpen && (
        <ul className={styles.dropdown}>
          {options?.map((i) => (
            <li
              key={i.value}
              onClick={(e) => {
                e.stopPropagation();
                onSelect(i.value);
              }}
              className={`${styles.label}${
                i.value === value ? ` ${styles.selected}` : ''
              }`}
            >
              {i.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Select;
