import React from 'react';
import styles from './SearchInput.module.scss';
import SearchIcon from '../../assets/icons/ic_search.svg';

type SearchInputProps = {
  value: string;
  onChangeText: (value: string) => void;
  placeholder?: string;
};

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChangeText,
  placeholder,
}) => {
  return (
    <div className={`${styles.container} ${value && styles.active}`}>
      <input
        className={styles.input}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChangeText(e.target.value)}
      />
      <img src={SearchIcon} alt="icon" className={styles.icon} />
    </div>
  );
};

export default SearchInput;
