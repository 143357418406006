import React, { useState } from 'react';
import {
  DayPickerRangeController,
  FocusedInputShape,
  isInclusivelyAfterDay,
} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './DatePicker.scss';
import moment from 'moment';
import { DateRangeType } from '../../hooks/useDateRange';
import NavPrevIcon from '../../assets/icons/ic_nav_prev.svg';
import NavNextIcon from '../../assets/icons/ic_nav_next.svg';

type DatePickerProps = {
  dateRange: DateRangeType;
  setDateRange: (dateRange: DateRangeType) => void;
};

const DatePicker: React.FC<DatePickerProps> = ({ dateRange, setDateRange }) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    'startDate',
  );

  return (
    <div>
      <DayPickerRangeController
        initialVisibleMonth={() => moment().add(-1, 'M')}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
        onDatesChange={({ startDate, endDate }) => {
          setDateRange({ startDate, endDate });
        }}
        isOutsideRange={(day) =>
          isInclusivelyAfterDay(day, moment().startOf('day'))
        }
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => {
          setFocusedInput(!focusedInput ? 'startDate' : focusedInput);
        }}
        numberOfMonths={2}
        hideKeyboardShortcutsPanel
        noBorder
        daySize={40}
        navPrev={
          <img src={NavPrevIcon} alt="Prev" className="date-picker-nav-prev" />
        }
        navNext={
          <img src={NavNextIcon} alt="Next" className="date-picker-nav-next" />
        }
        weekDayFormat="ddd"
        verticalBorderSpacing={4}
        minimumNights={0}
      />
    </div>
  );
};

export default DatePicker;
