import axios from 'axios';
import { getAuthKey } from '../store';
import { catchApiMessage } from '../utils/catchApiMessage';

export const getTransactions = (partnerId?: string) =>
  axios
    .get('/transactions', {
      params: {
        token: getAuthKey(),
        partnerId,
      },
    })
    .then((res) => res.data)
    .catch(catchApiMessage);
