import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Withdrawal } from '../../models/common';
import { userActions } from '../userSlice/userSlice';

const initialState: Withdrawal[] = [];

const withdrawalsSlice = createSlice({
  name: 'withdrawals',
  initialState,
  reducers: {
    saveWithdrawals: (state, action: PayloadAction<Withdrawal[]>) => {
      return action.payload;
    },
  },
  extraReducers: {
    [userActions.logOut.type]: () => initialState,
  },
});

export const withdrawalsActions = withdrawalsSlice.actions;

export default withdrawalsSlice.reducer;
