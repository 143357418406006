import { withdrawalsApi } from '../../api';
import { withdrawalsActions } from './withdrawalsSlice';
import { AppThunk } from '..';

export const getWithdrawalsThunk = (): AppThunk => async (dispatch) => {
  const response = await withdrawalsApi.getWithdrawals();
  dispatch(withdrawalsActions.saveWithdrawals(response));
};

export const setWithdrawalStatusThunk =
  (payment_id: string, status: string): AppThunk =>
  async (dispatch) => {
    await withdrawalsApi.setWithdrawalStatus(payment_id, status);
    await dispatch(getWithdrawalsThunk());
  };
