import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Transaction } from '../../models/common';
import { userActions } from '../userSlice/userSlice';

const initialState: Transaction[] = [];

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    saveTransactions: (state, action: PayloadAction<Transaction[]>) => {
      return action.payload;
    },
  },
  extraReducers: {
    [userActions.logOut.type]: () => initialState,
  },
});

export const transactionsActions = transactionsSlice.actions;

export default transactionsSlice.reducer;
